import {EEnvKey} from 'modules/appConfig/enum/EEnvKey';
import {EType} from 'modules/environment/enums/EEnviromentType';
import {getEnviromentType} from 'modules/environment/getEnviromentType';

/* eslint-disable no-process-env */
export abstract class AppConfigService {

    static getEnvironment(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_ENVIRONMENT);
    }

    static getTitle(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_TITLE);
    }

    static getNehnutelnostiUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_NEHNUTELNOSTI_URL);
    }

    static getNehnutelnostiNestApiUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_NEHNUTELNOSTI_NEST_API_URL);
    }

    static getOauthApiUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_OAUTH_API_URL);
    }

    static getOauthCallbackUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_OAUTH_CALLBACK_URL);
    }

    static getPlatformProjectName(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_PLATFORM_PROJECT_NAME);
    }

    static getMapTileLayerUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_MAP_TILE_LAYER_URL);
    }

    static getGoogleRecaptchaSitekey(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY);
    }

    static getGoogleInvisibleRecaptchaSitekey(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_GOOGLE_RECAPTCHA_INVISIBLE_SITEKEY);
    }

    static getAssetsServer(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_ASSETS_SERVER);
    }

    static getFbPixelId(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_FB_PIXEL_ID);
    }

    static getRtbHouseId(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_RTB_HOUSE_ID);
    }

    static getGoogleTagManagerId(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID);
    }

    static getNehnutelnostiAdminUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_URL);
    }

    static getPlatformParameterServiceUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_PLATFORM_PARAMETER_SERVICE_URL);
    }

    static getSpPrivacyManagerId(): number {
        return parseInt(this.getEnvValue(EEnvKey.NEXT_PUBLIC_SP_PRIVACY_MANAGER_ID), 10);
    }

    static getChromiumBinaryPath(): string {
        return this.getEnvValue(EEnvKey.CHROMIUM_BINARY_PATH);
    }

    static isCacheEnabled(): boolean {
        return this.getEnvValue(EEnvKey.CACHE_ENABLED) == 'true';
    }

    static getKeyDbIp(): string {
        return this.getEnvValue(EEnvKey.KEYDB_IP);
    }

    static getKeyDbPort(): number {
        return parseInt(this.getEnvValue(EEnvKey.KEYDB_PORT), 10);
    }

    static getGoogleOauthClientId(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID);
    }

    static getNehnutelnostiAdminApiUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_API_URL);
    }

    static getNewNehnutelnostiAdminUrl(): string {
        return this.getEnvValue(EEnvKey.NEXT_PUBLIC_NEW_NEHNUTELNOSTI_ADMIN_URL);
    }

    private static getEnvValue(env: EEnvKey): string {
        const isNextPublic = env.includes('NEXT_PUBLIC_');
        if (getEnviromentType() === EType.FRONT_END && !isNextPublic) {
            throw new Error(`${env} is only for back-end.`);
        }

        switch (env) {
        case EEnvKey.CHROMIUM_BINARY_PATH:
            return process.env.CHROMIUM_BINARY_PATH;
        case EEnvKey.CACHE_ENABLED:
            return process.env.CACHE_ENABLED;
        case EEnvKey.KEYDB_IP:
            return process.env.KEYDB_IP;
        case EEnvKey.KEYDB_PORT:
            return process.env.KEYDB_PORT;
        case EEnvKey.NEXT_PUBLIC_ENVIRONMENT:
            return process.env.NEXT_PUBLIC_ENVIRONMENT;
        case EEnvKey.NEXT_PUBLIC_TITLE:
            return process.env.NEXT_PUBLIC_TITLE;
        case EEnvKey.NEXT_PUBLIC_NEHNUTELNOSTI_URL:
            return process.env.NEXT_PUBLIC_NEHNUTELNOSTI_URL;
        case EEnvKey.NEXT_PUBLIC_NEHNUTELNOSTI_NEST_API_URL:
            return process.env.NEXT_PUBLIC_NEHNUTELNOSTI_NEST_API_URL;
        case EEnvKey.NEXT_PUBLIC_OAUTH_API_URL:
            return process.env.NEXT_PUBLIC_OAUTH_API_URL;
        case EEnvKey.NEXT_PUBLIC_OAUTH_CALLBACK_URL:
            return process.env.NEXT_PUBLIC_OAUTH_CALLBACK_URL;
        case EEnvKey.NEXT_PUBLIC_PLATFORM_PROJECT_NAME:
            return process.env.NEXT_PUBLIC_PLATFORM_PROJECT_NAME;
        case EEnvKey.NEXT_PUBLIC_MAP_TILE_LAYER_URL:
            return process.env.NEXT_PUBLIC_MAP_TILE_LAYER_URL;
        case EEnvKey.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY:
            return process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY;
        case EEnvKey.NEXT_PUBLIC_GOOGLE_RECAPTCHA_INVISIBLE_SITEKEY:
            return process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_INVISIBLE_SITEKEY;
        case EEnvKey.NEXT_PUBLIC_ASSETS_SERVER:
            return process.env.NEXT_PUBLIC_ASSETS_SERVER;
        case EEnvKey.NEXT_PUBLIC_FB_PIXEL_ID:
            return process.env.NEXT_PUBLIC_FB_PIXEL_ID;
        case EEnvKey.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID:
            return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
        case EEnvKey.NEXT_PUBLIC_RTB_HOUSE_ID:
            return process.env.NEXT_PUBLIC_RTB_HOUSE_ID;
        case EEnvKey.NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_URL:
            return process.env.NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_URL;
        case EEnvKey.NEXT_PUBLIC_PLATFORM_PARAMETER_SERVICE_URL:
            return process.env.NEXT_PUBLIC_PLATFORM_PARAMETER_SERVICE_URL;
        case EEnvKey.NEXT_PUBLIC_SP_PRIVACY_MANAGER_ID:
            return process.env.NEXT_PUBLIC_SP_PRIVACY_MANAGER_ID;
        case EEnvKey.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID:
            return process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID;
        case EEnvKey.NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_API_URL:
            return process.env.NEXT_PUBLIC_NEHNUTELNOSTI_ADMIN_API_URL;
        case EEnvKey.NEXT_PUBLIC_NEW_NEHNUTELNOSTI_ADMIN_URL:
            return process.env.NEXT_PUBLIC_NEW_NEHNUTELNOSTI_ADMIN_URL;
        }
    }

}
